/* Built In Imports */
import { useRouter } from 'next/router';
import {
    useEffect, useState
} from 'react';

/* External Imports */
import { Box } from '@chakra-ui/react';

/* Internal Imports */
/* Components */
import MidContainer from '@Layout/MidContainer';

/* Services */

/**
 * Renders the Us Donation Employer Match component
 *
 * @param {object} section - Data for section
 * @returns {ReactElement} Us Donation Employer Match component
 */

const UsDonationEmployerMatch = ({ section, shouldLoad }) => {
  const [loaded, setLoaded] = useState(false);
  const router = useRouter();

  const getScript = url =>
    new Promise((resolve, reject) => {
      console.log('getScript');
      let old = document.getElementById('hello');
      if (old !== null) {
        console.log('removing old script');
        document.head.removeChild(old);
      }
      const script = document.createElement('script');
      script.src = url;
      script.id = 'hello';
      script.async = true;
      script.onerror = reject;
      script.onload = script.onreadystatechange = function () {
        const loadState = this.readyState;
        if (loadState && loadState !== 'loaded' && loadState !== 'complete')
          return;
        script.onload = script.onreadystatechange = null;
        resolve();
      };

      console.log('script', script);
      document.head.appendChild(script);

      return script;
    });

  const getStyle = url =>
    new Promise((resolve, reject) => {
      console.log('getStyle');
      let old = document.getElementById('world');
      if (old !== null) {
        console.log('removing old link');
        document.head.removeChild(old);
      }
      const style = document.createElement('link');
      style.rel = 'stylesheet';
      style.id = 'world';
      style.type = 'text/css';
      style.href = url;
      style.onerror = reject;
      style.onload = style.onreadystatechange = function () {
        const loadState = this.readyState;
        if (loadState && loadState !== 'loaded' && loadState !== 'complete')
          return;
        style.onload = style.onreadystatechange = null;
        resolve();
      };

      document.head.appendChild(style);
    });

  // useLayoutEffect(() => {
  //   // if (window !== undefined) {
  //  consoleLog('calling his');
  //   // consoleLog('id before', document.getElementById('dd-container'));
  //   getScript('https://doublethedonation.com/api/js/ddplugin.js')
  //     .then(function () {
  //       getStyle('https://doublethedonation.com/api/css/ddplugin.css')
  //         .then(function () {
  //           setLoaded(true);
  //           console.log('loaded');
  //           let DDCONF = { API_KEY: 'ZGNkYjRjMDAtNDM0' };
  //           if (window.doublethedonation) {
  //             doublethedonation.plugin.load_plugin();
  //             doublethedonation.plugin.load_config();
  //             // doublethedonation.plugin.load_streamlined_input();
  //             doublethedonation.plugin.check_vendor_fields();
  //             doublethedonation.plugin.clear_company();
  //             doublethedonation.plugin.debug();
  //             let streamlined_search = document.getElementById('dd-container');
  //             // document.head.appendChild(script);
  //             const div = document.createElement('div');
  //             const div2 = document.createElement('div');
  //             const div3 = document.createElement('div');
  //             div.id = 'placeholder';
  //             div2.id = 'plugin';
  //             div3.id = 'config';
  //             document.body.appendChild(div);
  //             document.body.appendChild(div2);
  //             document.body.appendChild(div3);
  //             doublethedonation.plugin.load_plugin(streamlined_search); // Load the search with this element
  //             // doublethedonation.plugin.load_streamlined_input(
  //             //   streamlined_search
  //             // ); // Load the search with this element
  //             doublethedonation.plugin.check_vendor_fields(streamlined_search);
  //             doublethedonation.plugin.load_config(streamlined_search); // Load the search with this element
  //             doublethedonation.plugin.clear_company(streamlined_search); // Load the search with this element
  //             doublethedonation.plugin.debug(streamlined_search); // Load the search with this element
  //           }
  //           console.log('id', document.getElementById('dd-container').children);
  //           console.log('double window', window.doublethedonation);
  //         })
  //         .catch(err => {
  //           console.log('err', err);
  //         });
  //     })
  //     .catch(err => {
  //  consoleLog('err scr', err);
  //     });

  //   // }
  // }, [router]);

  // useEffect(() => {
  //   // if (router.asPath.indexOf('/us/') > -1) {
  //  consoleLog('router.asPath', document.getElementById('dd-container'));

  //   // }
  // }, [router.asPath]);

  useEffect(() => {
    // const loadScript = () =>
    // new Promise((resolve, reject) => {
    getScript('https://doublethedonation.com/api/js/ddplugin.js')
      .then(function () {
        getStyle('https://doublethedonation.com/api/css/ddplugin.css')
          .then(function () {
            setLoaded(true);
          })
          .catch(err => {
            console.log('style err', err);
          });
      })
      .catch(err => {
        console.log('script err', err);
      });
    // });

    // Needed for cleaning residue left by the external script that can only be removed by reloading the page
    const onRouterChange = url => {
      // router.reload();
      // consoleLog('url', url);
    };

    const onRouterComp = url => {
      // router.reload();
      // loadScript()
      //   .then(res => {
      //  consoleLog(
      //       'idlength',
      //       document.getElementById('dd-container').children.length
      //     );
      //     if (document.getElementById('dd-container')?.children?.length === 1) {
      //       setLoaded(true);
      //  consoleLog('double window', window.doublethedonation);
      //     } else {
      //       router.reload();
      //     }
      //   })
      //   .catch(err => {
      //     setLoaded(false);
      //  consoleLog('error', err);
      //   });
      // if (url.indexOf('/us/') || url.indexOf('/ca/')) {
      //  consoleLog('url com', url);
      //   router.reload();
      // }
      if (shouldLoad) {
        // consoleLog('url com', url);
        router.reload();
      }
    };

    router.events.on('routeChangeStart', onRouterChange);
    router.events.on('routeChangeComplete', onRouterComp);

    return () => {
      router.events.off('routeChangeStart', onRouterChange);
      router.events.off('routeChangeComplete', onRouterComp);
      document.head.removeAttribute(document.getElementById('hello'));
      document.head.removeAttribute(document.getElementById('world'));
    };
  }, [router]);

  return (
    <MidContainer>
      <Box m="50px 0" id="parentContainer">
        {/* <script>var DDCONF = {API_KEY: "ZGNkYjRjMDAtNDM0" };</script> */}
        {/* <script src="https://doublethedonation.com/api/js/ddplugin.js"></script>

      <link href="https://doublethedonation.com/api/css/ddplugin.css" rel="stylesheet" /> */}

        {loaded && (
          <div id="dd-container">
            <a href="https://doublethedonation.com/matching-grant-resources/matching-gift-basics/">
              Matching Gift
            </a>{' '}
            and{' '}
            <a href="https://doublethedonation.com/matching-grant-resources/volunteer-grant-basics/">
              Volunteer Grant
            </a>{' '}
            information provided by
            <br />
            <a href="https://doublethedonation.com">
              <img
                alt="Powered by Double the Donation"
                src="https://doublethedonation.com/api/img/powered-by.png"
              />
            </a>
          </div>
        )}
      </Box>
    </MidContainer>
  );
};

export default UsDonationEmployerMatch;

// const UsDonationEmployerMatch = ({ section }) => {
//   const [loaded, setLoaded] = useState(false);
//   const DDCONF = { API_KEY: 'ZGNkYjRjMDAtNDM0' };
//   const scriptRef = useRef();
//   const router = useRouter();

//   const getScript = url =>
//     new Promise((resolve, reject) => {
//  consoleLog('getScript');
//       const script = document.createElement('script');
//       script.src = url;
//       script.async = true;
//       script.onerror = reject;
//       script.onload = script.onreadystatechange = function () {
//         const loadState = this.readyState;
//         if (loadState && loadState !== 'loaded' && loadState !== 'complete')
//           return;
//         script.onload = script.onreadystatechange = null;
//         resolve();
//       };

//       document.head.appendChild(script);
//     });

//   const getStyle = url =>
//     new Promise((resolve, reject) => {
//  consoleLog('getStyle');
//       const style = document.createElement('link');
//       style.rel = 'stylesheet';
//       style.type = 'text/css';
//       style.href = url;
//       style.onerror = reject;
//       style.onload = style.onreadystatechange = function () {
//         const loadState = this.readyState;
//         if (loadState && loadState !== 'loaded' && loadState !== 'complete')
//           return;
//         style.onload = style.onreadystatechange = null;
//         resolve();
//       };

//       document.head.appendChild(style);
//     });

//   // useEffect(() => {
//   //   getScript('https://doublethedonation.com/api/js/ddplugin.js').then(
//   //     function () {
//   //       getStyle('https://doublethedonation.com/api/css/ddplugin.css').then(
//   //         function () {
//   //           // consoleLog('window', window.doublethedonation);
//   //           if (window.doublethedonation) {
//   //             window.doublethedonation.plugin.load_streamlined_input(); // Loads the search into a DOM element with id="dd-company-name-input"
//   //             let streamlined_search = document.getElementById('dd-container');
//   //             const div = document.createElement('div');
//   //             div.id = 'placeholder';
//   //             document.body.appendChild(div);
//   //             window.doublethedonation.plugin.load_streamlined_input(
//   //               streamlined_search
//   //             ); // Load the search with this element
//   //             setLoaded(true);
//   //           }
//   //           // if (window.doublethedonation) { // Don't break your page if our plugin doesn't load for any reason

//   //           //   doublethedonation.plugin.load_streamlined_input(); // Loads the search into a DOM element with id="dd-company-name-input"
//   //           //   // Alternatively, if you create DOM elements dynamically, you could also call it with a DOM element specified:
//   //           // let streamlined_search = document.getElementById('dd-container');
//   //           //   // document.head.appendChild(script);
//   //           //   const div = document.createElement('div');
//   //           //   div.id = "placeholder";
//   //           //   document.body.appendChild(div);
//   //           //   // streamlined_search.after("#placeholder"); // Insert it somewhere
//   //           //  consoleLog('doublethedonation', doublethedonation, streamlined_search[0]);
//   //           //   doublethedonation.plugin.load_streamlined_input(streamlined_search); // Load the search with this element
//   //           // }
//   //         }
//   //       );
//   //     }
//   //   );
//   // }, []);

//   useEffect(() => {
//     if (window !== undefined) {
//       if (window?.doublethedonation) {
//         window?.doublethedonation.plugin.load_streamlined_input(
//           scriptRef.current
//         ); // Load the search with this element
//         setLoaded(true);
//       }
//     } else {
//  consoleLog('window undefined');
//     }

//     return () => {
//       scriptRef.current = null;
//       setLoaded(false);
//     };
//   }, [loaded, router]);

//   // consoleLog(
//   //   'window',
//   //   window?.doublethedonation.plugin.load_streamlined_input(scriptRef.current)
//   // );

//   return (
//     <MidContainer>
//       <Script
//         src="https://doublethedonation.com/api/js/ddplugin.js"
//         strategy="beforeInteractive"
//         // async // cannot be asynchronous, I don't know the reason why yet.
//       />
//       <Script
//         src="https://doublethedonation.com/api/css/ddplugin.css"
//         strategy="beforeInteractive"
//         // async
//       />
//       <Box m="50px 0">
//         {loaded && (
//           <div>
//             <div id="dd-container" ref={scriptRef} />
//             <div id="placeholder" />
//             <div>
//               <a href="https://doublethedonation.com/matching-grant-resources/matching-gift-basics/">
//                 Matching Gift
//               </a>{' '}
//               and{' '}
//               <a href="https://doublethedonation.com/matching-grant-resources/volunteer-grant-basics/">
//                 Volunteer Grant
//               </a>{' '}
//               information provided by
//               <br />
//               <a href="https://doublethedonation.com">
//                 <img
//                   alt="Powered by Double the Donation"
//                   src="https://doublethedonation.com/api/img/powered-by.png"
//                 />
//               </a>
//             </div>
//           </div>
//         )}
//       </Box>
//     </MidContainer>
//   );
// };
